import styled, { device, themeGet } from "@styled";

export const QuoteFormWrapper = styled.section`
  margin-top: -100px;
  position: relative;
  z-index: 9;
  padding-bottom: 40px;
  ${device.xxlarge} {
    margin-top: -150px;
  }
`;
export const QuoteFormInner = styled.div`
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  padding: 25px 15px;
  border-radius: 13px;
  background: #fff;
  min-height: 600px; 
  position: relative;
  // overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
   
  ${device.medium} {
    padding: 25px;
  }
  ${device.large} {
    padding: 40px;
  }
`;
export const TagLine = styled.div`
  color: rgb(51 13 102);;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  line-height: 2;
  margin-bottom: 5px;
  span {
    transform: skew(0deg, 0deg);
    display: inline-block;
    color: ${themeGet("colors.primary")};
  }
`;
// export const FormSkeleton = styled.div`
// display: grid;
// grid-template-columns: repeat(2, 1fr);
// grid-gap: 10px 50px;

// position: absolute;
// top: 0;
// left: 0;
// right: 0;
// bottom: 0;
// padding: 40px;
// background: #fff;
// z-index: 9;
// & > div {
//   &:nth-child(7), &:nth-child(8), &:nth-child(9)  {
//     grid-column: span 2;
//   }
//   }
// `
export const FormSkeleton = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px 50px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px;
  background: #fff;
  z-index: 9;
   & > div {
    &:nth-child(1), &:nth-child(1), &:nth-child(1)  {
       grid-column: span 2;
       display: flex;
       justify-content: center;
    }
  }
  & > div {
    &:nth-child(7), &:nth-child(8), &:nth-child(9)  {
      grid-column: span 2;
    }
  }
`